import { IRoot } from "../interfaces/IPos";

export const posData: IRoot = {
  Webshop: {
    Products: [
      {
        ProductName: "SEPAY Mini 4G",
        ImageUrl: "/images/webshop/SEPAY Mini 4G.png",
        InvoiceCode: "N4",
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Buck Mini 4G",
          },
          {
            Locale: "de-DE",
            Description: "Buck Mini 4G",
          },
          {
            Locale: "en-GB",
            Description: "Buck Mini 4G",
          },
          {
            Locale: "fr-FR",
            Description: "Buck Mini 4G",
          },
        ],
        ProductFeatures: [
          {
            Type: "BuckarooFeatures_256",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Batterijduur",
              },
              {
                Locale: "de-DE",
                Description: "Akkulaufzeit",
              },
              {
                Locale: "en-GB",
                Description: "Battery life",
              },
              {
                Locale: "fr-FR",
                Description: "Autonomie de la batterie",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "8-12 uur",
              },
              {
                Locale: "de-DE",
                Description: "8-12 Stunden",
              },
              {
                Locale: "en-GB",
                Description: "8-12 hours",
              },
              {
                Locale: "fr-FR",
                Description: "8-12 heures",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_260",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Contactloos betalen",
              },
              {
                Locale: "de-DE",
                Description: "Kontaktloses Bezahlen",
              },
              {
                Locale: "en-GB",
                Description: "Contactless payment",
              },
              {
                Locale: "fr-FR",
                Description: "Paiement sans contact",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_261",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Kassa koppeling",
              },
              {
                Locale: "de-DE",
                Description: "Kassenanschluss",
              },
              {
                Locale: "en-GB",
                Description: "Cash Register integration",
              },
              {
                Locale: "fr-FR",
                Description: "Intégration de caisse",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_266",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Pinbon",
              },
              {
                Locale: "de-DE",
                Description: "Quittung",
              },
              {
                Locale: "en-GB",
                Description: "Receipt",
              },
              {
                Locale: "fr-FR",
                Description: "Ticket",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "digitaal",
              },
              {
                Locale: "de-DE",
                Description: "digital",
              },
              {
                Locale: "en-GB",
                Description: "digital",
              },
              {
                Locale: "fr-FR",
                Description: "digital",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_258",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Verbinding",
              },
              {
                Locale: "de-DE",
                Description: "Verbindung",
              },
              {
                Locale: "en-GB",
                Description: "Connection",
              },
              {
                Locale: "fr-FR",
                Description: "Connexion",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "4G",
              },
              {
                Locale: "de-DE",
                Description: "4G",
              },
              {
                Locale: "en-GB",
                Description: "4G",
              },
              {
                Locale: "fr-FR",
                Description: "4G",
              },
            ],
          },
        ],
        Subscriptions: [
          {
            Type: "rent",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Huur",
              },
              {
                Locale: "de-DE",
                Description: "Miete",
              },
              {
                Locale: "en-GB",
                Description: "Short-term Rent",
              },
              {
                Locale: "fr-FR",
                Description: "Location",
              },
            ],
            SubscriptionInvoiceCode: "9025",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 150.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 0.0,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "HTV",
                Fees: [
                  {
                    Price: 0.2,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Transactiekosten",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Transaktionskosten",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Transaction costs",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Coûts de transaction",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "1-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 1 an",
              },
            ],
            SubscriptionInvoiceCode: "_LN41",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 25.55,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_3_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 3 jaar",
              },
              {
                Locale: "de-DE",
                Description: "3-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "3-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 3 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LN43",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 22.9,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "buy_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Koop 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1 Jahr Kaufvertrag",
              },
              {
                Locale: "en-GB",
                Description: "Purchase with 1-year contract ",
              },
              {
                Locale: "fr-FR",
                Description: "Contrat d'achat d'un an",
              },
            ],
            SubscriptionInvoiceCode: "_KN41",
            Fees: [
              {
                Price: 169.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 12.4,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_5_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 5 jaar",
              },
              {
                Locale: "de-DE",
                Description: "5-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "5-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 5 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LN45",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 19.0,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        ProductName: "SEPAY Mobiel 4G",
        ImageUrl: "/images/webshop/sepay-mobiel-4g.png",
        InvoiceCode: "N5",
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Buck Mobile 4G",
          },
          {
            Locale: "de-DE",
            Description: "Buck Mobile 4G",
          },
          {
            Locale: "en-GB",
            Description: "Buck Mobile 4G",
          },
          {
            Locale: "fr-FR",
            Description: "Buck Mobile 4G",
          },
        ],
        ProductFeatures: [
          {
            Type: "BuckarooFeatures_257",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Batterijduur",
              },
              {
                Locale: "de-DE",
                Description: "Akkulaufzeit",
              },
              {
                Locale: "en-GB",
                Description: "Battery life",
              },
              {
                Locale: "fr-FR",
                Description: "Autonomie de la batterie",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "36-72 uur",
              },
              {
                Locale: "de-DE",
                Description: "36-72 Stunden",
              },
              {
                Locale: "en-GB",
                Description: "36-72 hours",
              },
              {
                Locale: "fr-FR",
                Description: "36-72 heures",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_260",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Contactloos betalen",
              },
              {
                Locale: "de-DE",
                Description: "Kontaktloses Bezahlen",
              },
              {
                Locale: "en-GB",
                Description: "Contactless payment",
              },
              {
                Locale: "fr-FR",
                Description: "Paiement sans contact",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_261",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Kassa koppeling",
              },
              {
                Locale: "de-DE",
                Description: "Kassenanschluss",
              },
              {
                Locale: "en-GB",
                Description: "Cash Register integration",
              },
              {
                Locale: "fr-FR",
                Description: "Intégration de caisse",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_263",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Pinbon",
              },
              {
                Locale: "de-DE",
                Description: "Quittung",
              },
              {
                Locale: "en-GB",
                Description: "Receipt",
              },
              {
                Locale: "fr-FR",
                Description: "Ticket",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "geprint",
              },
              {
                Locale: "de-DE",
                Description: "gedruckt",
              },
              {
                Locale: "en-GB",
                Description: "printed",
              },
              {
                Locale: "fr-FR",
                Description: "imprimé",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_258",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Verbinding",
              },
              {
                Locale: "de-DE",
                Description: "Verbindung",
              },
              {
                Locale: "en-GB",
                Description: "Connection",
              },
              {
                Locale: "fr-FR",
                Description: "Connexion",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "4G",
              },
              {
                Locale: "de-DE",
                Description: "4G",
              },
              {
                Locale: "en-GB",
                Description: "4G",
              },
              {
                Locale: "fr-FR",
                Description: "4G",
              },
            ],
          },
        ],
        Subscriptions: [
          {
            Type: "rent",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Huur",
              },
              {
                Locale: "de-DE",
                Description: "Miete",
              },
              {
                Locale: "en-GB",
                Description: "Short-term Rent",
              },
              {
                Locale: "fr-FR",
                Description: "Location",
              },
            ],
            SubscriptionInvoiceCode: "9025",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 150.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 0.0,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "HTV",
                Fees: [
                  {
                    Price: 0.2,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Transactiekosten",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Transaktionskosten",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Transaction costs",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Coûts de transaction",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "1-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 1 an",
              },
            ],
            SubscriptionInvoiceCode: "_LN51",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 43.0,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_3_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 3 jaar",
              },
              {
                Locale: "de-DE",
                Description: "3-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "3-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 3 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LN53",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 33.95,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "buy_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Koop 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1 Jahr Kaufvertrag",
              },
              {
                Locale: "en-GB",
                Description: "Purchase with 1-year contract ",
              },
              {
                Locale: "fr-FR",
                Description: "Contrat d'achat d'un an",
              },
            ],
            SubscriptionInvoiceCode: "_KN51",
            Fees: [
              {
                Price: 490.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 19.45,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_5_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 5 jaar",
              },
              {
                Locale: "de-DE",
                Description: "5-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "5-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 5 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LN55",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 28.35,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        ProductName: "SEPAY Smart",
        ImageUrl: "/images/webshop/SEPAY_Buckaroo_Smart-staand.png",
        InvoiceCode: "N6",
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Buck Smart",
          },
          {
            Locale: "de-DE",
            Description: "Buck Smart",
          },
          {
            Locale: "en-GB",
            Description: "Buck Smart",
          },
          {
            Locale: "fr-FR",
            Description: "Buck Smart",
          },
        ],
        ProductFeatures: [
          {
            Type: "BuckarooFeatures_257",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Batterijduur",
              },
              {
                Locale: "de-DE",
                Description: "Akkulaufzeit",
              },
              {
                Locale: "en-GB",
                Description: "Battery life",
              },
              {
                Locale: "fr-FR",
                Description: "Autonomie de la batterie",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "36-72 uur",
              },
              {
                Locale: "de-DE",
                Description: "36-72 Stunden",
              },
              {
                Locale: "en-GB",
                Description: "36-72 hours",
              },
              {
                Locale: "fr-FR",
                Description: "36-72 heures",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_260",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Contactloos betalen",
              },
              {
                Locale: "de-DE",
                Description: "Kontaktloses Bezahlen",
              },
              {
                Locale: "en-GB",
                Description: "Contactless payment",
              },
              {
                Locale: "fr-FR",
                Description: "Paiement sans contact",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_261",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Kassa koppeling",
              },
              {
                Locale: "de-DE",
                Description: "Kassenanschluss",
              },
              {
                Locale: "en-GB",
                Description: "Cash Register integration",
              },
              {
                Locale: "fr-FR",
                Description: "Intégration de caisse",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_266",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Pinbon",
              },
              {
                Locale: "de-DE",
                Description: "Quittung",
              },
              {
                Locale: "en-GB",
                Description: "Receipt",
              },
              {
                Locale: "fr-FR",
                Description: "Ticket",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "digitaal",
              },
              {
                Locale: "de-DE",
                Description: "digital",
              },
              {
                Locale: "en-GB",
                Description: "digital",
              },
              {
                Locale: "fr-FR",
                Description: "digital",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_259",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Verbinding",
              },
              {
                Locale: "de-DE",
                Description: "Verbindung",
              },
              {
                Locale: "en-GB",
                Description: "Connection",
              },
              {
                Locale: "fr-FR",
                Description: "Connexion",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "4G + Wifi",
              },
              {
                Locale: "de-DE",
                Description: "4G + WLAN",
              },
              {
                Locale: "en-GB",
                Description: "4G + WiFI",
              },
              {
                Locale: "fr-FR",
                Description: "4G + Wifi",
              },
            ],
          },
        ],
        Subscriptions: [
          {
            Type: "rent",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Huur",
              },
              {
                Locale: "de-DE",
                Description: "Miete",
              },
              {
                Locale: "en-GB",
                Description: "Short-term Rent",
              },
              {
                Locale: "fr-FR",
                Description: "Location",
              },
            ],
            SubscriptionInvoiceCode: "9025",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 150.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 0.0,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "HTV",
                Fees: [
                  {
                    Price: 0.2,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Transactiekosten",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Transaktionskosten",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Transaction costs",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Coûts de transaction",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "1-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 1 an",
              },
            ],
            SubscriptionInvoiceCode: "_LN61",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 34.65,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_3_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 3 jaar",
              },
              {
                Locale: "de-DE",
                Description: "3-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "Buck Smart 3-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 3 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LN63",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 29.4,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "buy_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Koop 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1 Jahr Kaufvertrag",
              },
              {
                Locale: "en-GB",
                Description: "Purchase with 1-year contract ",
              },
              {
                Locale: "fr-FR",
                Description: "Contrat d'achat d'un an",
              },
            ],
            SubscriptionInvoiceCode: "_KN61",
            Fees: [
              {
                Price: 405.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 16.95,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_5_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 5 jaar",
              },
              {
                Locale: "de-DE",
                Description: "5-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "5-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 5 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LN65",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 25.55,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        ProductName: "SEPAY Smart (wit)",
        ImageUrl: "/images/webshop/BuckarooN6-Wit.png",
        InvoiceCode: "NA",
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Buck Smart (wit)",
          },
          {
            Locale: "de-DE",
            Description: "Buck Smart(weiss)",
          },
          {
            Locale: "en-GB",
            Description: "Buck Smart (white)",
          },
          {
            Locale: "fr-FR",
            Description: "Buck Smart(blanc)",
          },
        ],
        ProductFeatures: [
          {
            Type: "BuckarooFeatures_257",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Batterijduur",
              },
              {
                Locale: "de-DE",
                Description: "Akkulaufzeit",
              },
              {
                Locale: "en-GB",
                Description: "Battery life",
              },
              {
                Locale: "fr-FR",
                Description: "Autonomie de la batterie",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "36-72 uur",
              },
              {
                Locale: "de-DE",
                Description: "36-72 Stunden",
              },
              {
                Locale: "en-GB",
                Description: "36-72 hours",
              },
              {
                Locale: "fr-FR",
                Description: "36-72 heures",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_260",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Contactloos betalen",
              },
              {
                Locale: "de-DE",
                Description: "Kontaktloses Bezahlen",
              },
              {
                Locale: "en-GB",
                Description: "Contactless payment",
              },
              {
                Locale: "fr-FR",
                Description: "Paiement sans contact",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_261",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Kassa koppeling",
              },
              {
                Locale: "de-DE",
                Description: "Kassenanschluss",
              },
              {
                Locale: "en-GB",
                Description: "Cash Register integration",
              },
              {
                Locale: "fr-FR",
                Description: "Intégration de caisse",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_266",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Pinbon",
              },
              {
                Locale: "de-DE",
                Description: "Quittung",
              },
              {
                Locale: "en-GB",
                Description: "Receipt",
              },
              {
                Locale: "fr-FR",
                Description: "Ticket",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "digitaal",
              },
              {
                Locale: "de-DE",
                Description: "digital",
              },
              {
                Locale: "en-GB",
                Description: "digital",
              },
              {
                Locale: "fr-FR",
                Description: "digital",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_259",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Verbinding",
              },
              {
                Locale: "de-DE",
                Description: "Verbindung",
              },
              {
                Locale: "en-GB",
                Description: "Connection",
              },
              {
                Locale: "fr-FR",
                Description: "Connexion",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "4G + Wifi",
              },
              {
                Locale: "de-DE",
                Description: "4G + WLAN",
              },
              {
                Locale: "en-GB",
                Description: "4G + WiFI",
              },
              {
                Locale: "fr-FR",
                Description: "4G + Wifi",
              },
            ],
          },
        ],
        Subscriptions: [
          {
            Type: "lease_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "1-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 1 an",
              },
            ],
            SubscriptionInvoiceCode: "_LNA1",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 34.65,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_3_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 3 jaar",
              },
              {
                Locale: "de-DE",
                Description: "3-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "3-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 3 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LNA3",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 29.4,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "buy_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Koop 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1 Jahr Kaufvertrag",
              },
              {
                Locale: "en-GB",
                Description: "Purchase with 1-year contract ",
              },
              {
                Locale: "fr-FR",
                Description: "Contrat d'achat d'un an",
              },
            ],
            SubscriptionInvoiceCode: "_KNA1",
            Fees: [
              {
                Price: 405.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 16.95,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_5_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 5 jaar",
              },
              {
                Locale: "de-DE",
                Description: "5-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "5-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 5 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LNA5",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 25.55,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        ProductName: "SEPAY Smart Fixed",
        ImageUrl: "/images/webshop/BuckarooN6-Desktop.png",
        InvoiceCode: "NF",
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Buck Smart Fixed",
          },
          {
            Locale: "de-DE",
            Description: "Buck Smart Fixed",
          },
          {
            Locale: "en-GB",
            Description: "Buck Smart Fixed",
          },
          {
            Locale: "fr-FR",
            Description: "Buck Smart Fixed",
          },
        ],
        ProductFeatures: [
          {
            Type: "BuckarooFeatures_257",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Batterijduur",
              },
              {
                Locale: "de-DE",
                Description: "Akkulaufzeit",
              },
              {
                Locale: "en-GB",
                Description: "Battery life",
              },
              {
                Locale: "fr-FR",
                Description: "Autonomie de la batterie",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "36-72 uur",
              },
              {
                Locale: "de-DE",
                Description: "36-72 Stunden",
              },
              {
                Locale: "en-GB",
                Description: "36-72 hours",
              },
              {
                Locale: "fr-FR",
                Description: "36-72 heures",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_260",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Contactloos betalen",
              },
              {
                Locale: "de-DE",
                Description: "Kontaktloses Bezahlen",
              },
              {
                Locale: "en-GB",
                Description: "Contactless payment",
              },
              {
                Locale: "fr-FR",
                Description: "Paiement sans contact",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_261",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Kassa koppeling",
              },
              {
                Locale: "de-DE",
                Description: "Kassenanschluss",
              },
              {
                Locale: "en-GB",
                Description: "Cash Register integration",
              },
              {
                Locale: "fr-FR",
                Description: "Intégration de caisse",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_266",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Pinbon",
              },
              {
                Locale: "de-DE",
                Description: "Quittung",
              },
              {
                Locale: "en-GB",
                Description: "Receipt",
              },
              {
                Locale: "fr-FR",
                Description: "Ticket",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "digitaal",
              },
              {
                Locale: "de-DE",
                Description: "digital",
              },
              {
                Locale: "en-GB",
                Description: "digital",
              },
              {
                Locale: "fr-FR",
                Description: "digital",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_264",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Verbinding",
              },
              {
                Locale: "de-DE",
                Description: "Verbindung",
              },
              {
                Locale: "en-GB",
                Description: "Connection",
              },
              {
                Locale: "fr-FR",
                Description: "Connexion",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "LAN",
              },
              {
                Locale: "de-DE",
                Description: "LAN",
              },
              {
                Locale: "en-GB",
                Description: "LAN",
              },
              {
                Locale: "fr-FR",
                Description: "LAN",
              },
            ],
          },
        ],
        Subscriptions: [
          {
            Type: "lease_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "1-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 1 an",
              },
            ],
            SubscriptionInvoiceCode: "_LNF1",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 33.95,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_3_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 3 jaar",
              },
              {
                Locale: "de-DE",
                Description: "3-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "3-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 3 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LNF3",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 29.5,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "buy_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Koop 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1 Jahr Kaufvertrag",
              },
              {
                Locale: "en-GB",
                Description: "Purchase with 1-year contract ",
              },
              {
                Locale: "fr-FR",
                Description: "Contrat d'achat d'un an",
              },
            ],
            SubscriptionInvoiceCode: "_KNF1",
            Fees: [
              {
                Price: 350.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 16.5,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_5_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 5 jaar",
              },
              {
                Locale: "de-DE",
                Description: "5-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "5-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 5 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LNF5",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 24.25,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        ProductName: "SEPAY Smart Plus",
        ImageUrl: "/images/webshop/SEPAY_Buckaroo_Smart_Plus-staand.png",
        InvoiceCode: "N8",
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Buck Smart Plus",
          },
          {
            Locale: "de-DE",
            Description: "Buck Smart Plus",
          },
          {
            Locale: "en-GB",
            Description: "Buck Smart Plus",
          },
          {
            Locale: "fr-FR",
            Description: "Buck Smart Plus",
          },
        ],
        ProductFeatures: [
          {
            Type: "BuckarooFeatures_257",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Batterijduur",
              },
              {
                Locale: "de-DE",
                Description: "Akkulaufzeit",
              },
              {
                Locale: "en-GB",
                Description: "Battery life",
              },
              {
                Locale: "fr-FR",
                Description: "Autonomie de la batterie",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "36-72 uur",
              },
              {
                Locale: "de-DE",
                Description: "36-72 Stunden",
              },
              {
                Locale: "en-GB",
                Description: "36-72 hours",
              },
              {
                Locale: "fr-FR",
                Description: "36-72 heures",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_260",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Contactloos betalen",
              },
              {
                Locale: "de-DE",
                Description: "Kontaktloses Bezahlen",
              },
              {
                Locale: "en-GB",
                Description: "Contactless payment",
              },
              {
                Locale: "fr-FR",
                Description: "Paiement sans contact",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_261",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Kassa koppeling",
              },
              {
                Locale: "de-DE",
                Description: "Kassenanschluss",
              },
              {
                Locale: "en-GB",
                Description: "Cash Register integration",
              },
              {
                Locale: "fr-FR",
                Description: "Intégration de caisse",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_263",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Pinbon",
              },
              {
                Locale: "de-DE",
                Description: "Quittung",
              },
              {
                Locale: "en-GB",
                Description: "Receipt",
              },
              {
                Locale: "fr-FR",
                Description: "Ticket",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "geprint",
              },
              {
                Locale: "de-DE",
                Description: "gedruckt",
              },
              {
                Locale: "en-GB",
                Description: "printed",
              },
              {
                Locale: "fr-FR",
                Description: "imprimé",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_259",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Verbinding",
              },
              {
                Locale: "de-DE",
                Description: "Verbindung",
              },
              {
                Locale: "en-GB",
                Description: "Connection",
              },
              {
                Locale: "fr-FR",
                Description: "Connexion",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "4G + Wifi",
              },
              {
                Locale: "de-DE",
                Description: "4G + WLAN",
              },
              {
                Locale: "en-GB",
                Description: "4G + WiFI",
              },
              {
                Locale: "fr-FR",
                Description: "4G + Wifi",
              },
            ],
          },
        ],
        Subscriptions: [
          {
            Type: "rent",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Huur",
              },
              {
                Locale: "de-DE",
                Description: "Miete",
              },
              {
                Locale: "en-GB",
                Description: "Short-term Rent",
              },
              {
                Locale: "fr-FR",
                Description: "Location",
              },
            ],
            SubscriptionInvoiceCode: "9025",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 150.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 0.0,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "HTV",
                Fees: [
                  {
                    Price: 0.2,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Transactiekosten",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Transaktionskosten",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Transaction costs",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Coûts de transaction",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "1-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 1 an",
              },
            ],
            SubscriptionInvoiceCode: "_LN81",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 41.8,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_3_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 3 jaar",
              },
              {
                Locale: "de-DE",
                Description: "3-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "3-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 3 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LN83",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 32.7,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "buy_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Koop 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1 Jahr Kaufvertrag",
              },
              {
                Locale: "en-GB",
                Description: "Purchase with 1-year contract ",
              },
              {
                Locale: "fr-FR",
                Description: "Contrat d'achat d'un an",
              },
            ],
            SubscriptionInvoiceCode: "_KN81",
            Fees: [
              {
                Price: 470.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 18.2,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_5_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 5 jaar",
              },
              {
                Locale: "de-DE",
                Description: "5-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "5-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 5 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LN85",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 27.45,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        ProductName: "SEPAY Transactiedienst",
        ImageUrl: "/images/webshop/SEPAY Transactiedienst.png",
        InvoiceCode: "TD",
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Buck Transactiedienst",
          },
          {
            Locale: "de-DE",
            Description: "Buck-Transaktionsdienstleistungen",
          },
          {
            Locale: "en-GB",
            Description: "Buck Transaction Services",
          },
          {
            Locale: "fr-FR",
            Description: "Buck frais de transaction",
          },
        ],
        ProductFeatures: [],
        Subscriptions: [
          {
            Type: "transactionsservice",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Transactiedienst",
              },
              {
                Locale: "de-DE",
                Description: "Transaktionsdienstleistungen",
              },
              {
                Locale: "en-GB",
                Description: "Transaction services",
              },
              {
                Locale: "fr-FR",
                Description: "Service de transaction",
              },
            ],
            SubscriptionInvoiceCode: "_TD1",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 0.0,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        ProductName: "SEPAY Vast Neo",
        ImageUrl: "/images/webshop/sepay-vast-neo.png",
        InvoiceCode: "T6",
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Buck Fixed Neo",
          },
          {
            Locale: "de-DE",
            Description: "Buck Fixed Neo",
          },
          {
            Locale: "en-GB",
            Description: "Buck Fixed Neo",
          },
          {
            Locale: "fr-FR",
            Description: "Buck Fixed Neo ",
          },
        ],
        ProductFeatures: [
          {
            Type: "BuckarooFeatures_260",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Contactloos betalen",
              },
              {
                Locale: "de-DE",
                Description: "Kontaktloses Bezahlen",
              },
              {
                Locale: "en-GB",
                Description: "Contactless payment",
              },
              {
                Locale: "fr-FR",
                Description: "Paiement sans contact",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_261",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Kassa koppeling",
              },
              {
                Locale: "de-DE",
                Description: "Kassenanschluss",
              },
              {
                Locale: "en-GB",
                Description: "Cash Register integration",
              },
              {
                Locale: "fr-FR",
                Description: "Intégration de caisse",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_263",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Pinbon",
              },
              {
                Locale: "de-DE",
                Description: "Quittung",
              },
              {
                Locale: "en-GB",
                Description: "Receipt",
              },
              {
                Locale: "fr-FR",
                Description: "Ticket",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "geprint",
              },
              {
                Locale: "de-DE",
                Description: "gedruckt",
              },
              {
                Locale: "en-GB",
                Description: "printed",
              },
              {
                Locale: "fr-FR",
                Description: "imprimé",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_264",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Verbinding",
              },
              {
                Locale: "de-DE",
                Description: "Verbindung",
              },
              {
                Locale: "en-GB",
                Description: "Connection",
              },
              {
                Locale: "fr-FR",
                Description: "Connexion",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "LAN",
              },
              {
                Locale: "de-DE",
                Description: "LAN",
              },
              {
                Locale: "en-GB",
                Description: "LAN",
              },
              {
                Locale: "fr-FR",
                Description: "LAN",
              },
            ],
          },
        ],
        Subscriptions: [
          {
            Type: "rent",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Huur",
              },
              {
                Locale: "de-DE",
                Description: "Miete",
              },
              {
                Locale: "en-GB",
                Description: "Short-term Rent",
              },
              {
                Locale: "fr-FR",
                Description: "Location",
              },
            ],
            SubscriptionInvoiceCode: "9025",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 150.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 0.0,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "HTV",
                Fees: [
                  {
                    Price: 0.2,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Transactiekosten",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Transaktionskosten",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Transaction costs",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Coûts de transaction",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "buy_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Koop 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1 Jahr Kaufvertrag",
              },
              {
                Locale: "en-GB",
                Description: "Purchase with 1-year contract ",
              },
              {
                Locale: "fr-FR",
                Description: "Contrat d'achat d'un an",
              },
            ],
            SubscriptionInvoiceCode: "_KT61",
            Fees: [
              {
                Price: 290.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 14.9,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_3_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 3 jaar",
              },
              {
                Locale: "de-DE",
                Description: "3-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "3-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 3 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LT63",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 26.8,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "1-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 1 an",
              },
            ],
            SubscriptionInvoiceCode: "_LT61",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 30.15,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_5_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 5 jaar",
              },
              {
                Locale: "de-DE",
                Description: "5-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "5-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 5 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LT65",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 21.25,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        ProductName: "SEPAY Vast Neo DUO",
        ImageUrl: "/images/webshop/sepay-duo-neo.png",
        InvoiceCode: "D6",
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Buck Fixed Neo Duo",
          },
          {
            Locale: "de-DE",
            Description: "Buck Fixed Neo Duo",
          },
          {
            Locale: "en-GB",
            Description: "Buck Fixed Neo Duo",
          },
          {
            Locale: "fr-FR",
            Description: "Buck Fixed Neo Duo",
          },
        ],
        ProductFeatures: [
          {
            Type: "BuckarooFeatures_260",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Contactloos betalen",
              },
              {
                Locale: "de-DE",
                Description: "Kontaktloses Bezahlen",
              },
              {
                Locale: "en-GB",
                Description: "Contactless payment",
              },
              {
                Locale: "fr-FR",
                Description: "Paiement sans contact",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_261",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Kassa koppeling",
              },
              {
                Locale: "de-DE",
                Description: "Kassenanschluss",
              },
              {
                Locale: "en-GB",
                Description: "Cash Register integration",
              },
              {
                Locale: "fr-FR",
                Description: "Intégration de caisse",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "Ja",
              },
              {
                Locale: "de-DE",
                Description: "Ja",
              },
              {
                Locale: "en-GB",
                Description: "Yes",
              },
              {
                Locale: "fr-FR",
                Description: "Oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_267",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Kassiersunit",
              },
              {
                Locale: "de-DE",
                Description: "Kassierereinheit",
              },
              {
                Locale: "en-GB",
                Description: "Cashier unit",
              },
              {
                Locale: "fr-FR",
                Description: "Unité de caisse",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "ja",
              },
              {
                Locale: "de-DE",
                Description: "ja",
              },
              {
                Locale: "en-GB",
                Description: "yes",
              },
              {
                Locale: "fr-FR",
                Description: "oui",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_263",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Pinbon",
              },
              {
                Locale: "de-DE",
                Description: "Quittung",
              },
              {
                Locale: "en-GB",
                Description: "Receipt",
              },
              {
                Locale: "fr-FR",
                Description: "Ticket",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "geprint",
              },
              {
                Locale: "de-DE",
                Description: "gedruckt",
              },
              {
                Locale: "en-GB",
                Description: "printed",
              },
              {
                Locale: "fr-FR",
                Description: "imprimé",
              },
            ],
          },
          {
            Type: "BuckarooFeatures_264",
            Name: [
              {
                Locale: "nl-NL",
                Description: "Verbinding",
              },
              {
                Locale: "de-DE",
                Description: "Verbindung",
              },
              {
                Locale: "en-GB",
                Description: "Connection",
              },
              {
                Locale: "fr-FR",
                Description: "Connexion",
              },
            ],
            Values: [
              {
                Locale: "nl-NL",
                Description: "LAN",
              },
              {
                Locale: "de-DE",
                Description: "LAN",
              },
              {
                Locale: "en-GB",
                Description: "LAN",
              },
              {
                Locale: "fr-FR",
                Description: "LAN",
              },
            ],
          },
        ],
        Subscriptions: [
          {
            Type: "rent",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Huur",
              },
              {
                Locale: "de-DE",
                Description: "Miete",
              },
              {
                Locale: "en-GB",
                Description: "Short-term Rent",
              },
              {
                Locale: "fr-FR",
                Description: "Location",
              },
            ],
            SubscriptionInvoiceCode: "9025",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 150.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 0.0,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "HTV",
                Fees: [
                  {
                    Price: 0.2,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Transactiekosten",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Transaktionskosten",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Transaction costs",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Coûts de transaction",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "buy_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Koop 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1 Jahr Kaufvertrag",
              },
              {
                Locale: "en-GB",
                Description: "Purchase with 1-year contract ",
              },
              {
                Locale: "fr-FR",
                Description: "Contrat d'achat d'un an",
              },
            ],
            SubscriptionInvoiceCode: "_KD61",
            Fees: [
              {
                Price: 390.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 18.1,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_3_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 3 jaar",
              },
              {
                Locale: "de-DE",
                Description: "3-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "3-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 3 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LD63",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 30.7,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_1_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 1 jaar",
              },
              {
                Locale: "de-DE",
                Description: "1-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "1-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 1 an",
              },
            ],
            SubscriptionInvoiceCode: "_LD61",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 37.3,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            Type: "lease_5_year",
            SubscriptionProductNames: [
              {
                Locale: "nl-NL",
                Description: "Lease 5 jaar",
              },
              {
                Locale: "de-DE",
                Description: "5-Jahres-Mietvertrag",
              },
              {
                Locale: "en-GB",
                Description: "5-year Lease",
              },
              {
                Locale: "fr-FR",
                Description: "Bail de 5 ans",
              },
            ],
            SubscriptionInvoiceCode: "_LD65",
            Fees: [
              {
                Price: 0.0,
                Type: "BuyFee",
                Descriptions: null,
              },
              {
                Price: 58.0,
                Type: "OneTimeFee",
                Descriptions: null,
              },
              {
                Price: 24.95,
                Type: "RecurringFee",
                Descriptions: null,
              },
            ],
            TransactionProducts: [
              {
                TransactionProductInvoiceCode: "_TDV",
                Fees: [
                  {
                    Price: 0.11,
                    Type: "TransactionFee",
                    Descriptions: [
                      {
                        Locale: "nl-NL",
                        Description: "Variabel",
                      },
                      {
                        Locale: "de-DE",
                        Description: "Variable",
                      },
                      {
                        Locale: "en-GB",
                        Description: "Variable",
                      },
                      {
                        Locale: "fr-FR",
                        Description: "Variable",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    Accessories: [
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "10 Thermo printerpapier bonrollen",
          },
          {
            Locale: "de-DE",
            Description: "10 Thermo-Bon-Rollen",
          },
          {
            Locale: "en-GB",
            Description: "10 thermal receipt rolls",
          },
          {
            Locale: "fr-FR",
            Description: "10 rouleaux de reçus thermiques",
          },
        ],
        ImageUrl: "/images/webshop/10 Thermo printerpapier bonrollen.png",
        InvoiceCode: "_A10451",
        Compatibilities: [
          "SEPAY Mobiel 4G",
          "SEPAY Vast Neo",
          "SEPAY Vast Neo DUO",
          "SEPAY Smart Plus",
        ],
        Fees: [
          {
            Price: 9.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "12V Auto USB Adapter (2x USB) Excl. USB kabel.",
          },
          {
            Locale: "de-DE",
            Description: "12V Auto-USB-Adapter (2x USB), exkl. USB-Kabel",
          },
          {
            Locale: "en-GB",
            Description: "12V Car USB Adapter (2x USB) excl. USB cable",
          },
          {
            Locale: "fr-FR",
            Description: "Adaptateur USB de voiture 12 V (2x USB)",
          },
        ],
        ImageUrl: "/images/webshop/12V_Oplader.png",
        InvoiceCode: "_A0501",
        Compatibilities: [
          "SEPAY Mini 4G",
          "SEPAY Mobiel 4G",
          "SEPAY Smart",
          "SEPAY Smart Plus",
          "SEPAY Smart (wit)",
          "SEPAY Smart Fixed",
        ],
        Fees: [
          {
            Price: 9.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "20 Thermo printerpapier bonrollen",
          },
          {
            Locale: "de-DE",
            Description: "20 Thermo-Bon-Rollen",
          },
          {
            Locale: "en-GB",
            Description: "20 thermal receipt rolls",
          },
          {
            Locale: "fr-FR",
            Description: "20 rouleaux de reçus thermiques",
          },
        ],
        ImageUrl: "/images/webshop/20 Thermo printerpapier bonrollen.png",
        InvoiceCode: "_A20452",
        Compatibilities: [
          "SEPAY Mobiel 4G",
          "SEPAY Vast Neo",
          "SEPAY Vast Neo DUO",
          "SEPAY Smart Plus",
        ],
        Fees: [
          {
            Price: 16.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "220V USB Adapter (wit) (excl. USB kabel)",
          },
          {
            Locale: "de-DE",
            Description: "220-V-USB-Adapter (ohne USB-Kabel)",
          },
          {
            Locale: "en-GB",
            Description: "220V USB Adapter (excl USB cable)",
          },
          {
            Locale: "fr-FR",
            Description: "Adaptateur USB 220 V (hors câble USB)",
          },
        ],
        ImageUrl: "/images/webshop/220V_webshop.png",
        InvoiceCode: "_A0502",
        Compatibilities: [
          "SEPAY Mini 4G",
          "SEPAY Mobiel 4G",
          "SEPAY Smart",
          "SEPAY Smart Plus",
          "SEPAY Smart (wit)",
          "SEPAY Smart Fixed",
        ],
        Fees: [
          {
            Price: 9.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "30 Thermo printerpapier bonrollen",
          },
          {
            Locale: "de-DE",
            Description: "30 Thermo-Bon-Rollen",
          },
          {
            Locale: "en-GB",
            Description: "30 thermal receipt rolls",
          },
          {
            Locale: "fr-FR",
            Description: "30 rouleaux de reçus thermiques",
          },
        ],
        ImageUrl: "/images/webshop/30 Thermo printerpapier bonrollen.png",
        InvoiceCode: "_A30453",
        Compatibilities: [
          "SEPAY Mobiel 4G",
          "SEPAY Vast Neo",
          "SEPAY Vast Neo DUO",
          "SEPAY Smart Plus",
        ],
        Fees: [
          {
            Price: 22.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "5 Thermo printerpapier bonrollen",
          },
          {
            Locale: "de-DE",
            Description: "5 Thermo-Bon-Rollen",
          },
          {
            Locale: "en-GB",
            Description: "5 thermal receipt rolls",
          },
          {
            Locale: "fr-FR",
            Description: "5 rouleaux de reçus thermiques",
          },
        ],
        ImageUrl: "/images/webshop/5 Thermo printerpapier bonrollen.png",
        InvoiceCode: "_A5450",
        Compatibilities: [
          "SEPAY Mobiel 4G",
          "SEPAY Vast Neo",
          "SEPAY Vast Neo DUO",
          "SEPAY Smart Plus",
        ],
        Fees: [
          {
            Price: 4.75,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "50 Thermo printerpapier bonrollen",
          },
          {
            Locale: "de-DE",
            Description: "50 Thermo-Bon-Rollen",
          },
          {
            Locale: "en-GB",
            Description: "50 thermal receipt rolls",
          },
          {
            Locale: "fr-FR",
            Description: "50 rouleaux de reçus thermiques",
          },
        ],
        ImageUrl: "/images/webshop/50 Thermo printerpapier bonrollen.png",
        InvoiceCode: "_A50454",
        Compatibilities: [
          "SEPAY Mobiel 4G",
          "SEPAY Vast Neo",
          "SEPAY Vast Neo DUO",
          "SEPAY Smart Plus",
        ],
        Fees: [
          {
            Price: 36.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Aandrukrol printer SEPAY Mobiel (G2)",
          },
          {
            Locale: "de-DE",
            Description: "Andruckrolle für Drucker Sepay Mobile (G2)",
          },
          {
            Locale: "en-GB",
            Description: "Printer Roller Sepay Mobile (G2)",
          },
          {
            Locale: "fr-FR",
            Description: "Rouleau presseur pour imprimante Sepay Mobile (G2)",
          },
        ],
        ImageUrl: "/images/webshop/sepay_aandrukrol.png",
        InvoiceCode: "_A0515",
        Compatibilities: ["SEPAY Mobiel 4G", "SEPAY Smart Plus"],
        Fees: [
          {
            Price: 16.5,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Aandrukrol printer SEPAY Vast (T2)",
          },
          {
            Locale: "de-DE",
            Description: "Andruckrolle für Drucker Sepay Fixed (T2",
          },
          {
            Locale: "en-GB",
            Description: "Printer Roller Sepay Vast (T2)",
          },
          {
            Locale: "fr-FR",
            Description: "Rouleau presseur pour imprimante Sepay Fixed (T2)",
          },
        ],
        ImageUrl: "/images/webshop/sepay_aandrukrol.png",
        InvoiceCode: "_A0516",
        Compatibilities: ["SEPAY Vast Neo", "SEPAY Vast Neo DUO"],
        Fees: [
          {
            Price: 16.5,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Antraciete beschermhoes Buck Smart (N6)",
          },
          {
            Locale: "de-DE",
            Description: "Anthrazitfarbene Schutzhülle Buck Smart (N6)",
          },
          {
            Locale: "en-GB",
            Description: "Grey protective cover Buck Smart (N6)",
          },
          {
            Locale: "fr-FR",
            Description: "Étui de protection gris Buck Smart (N6)",
          },
        ],
        ImageUrl: "/images/webshop/n6-hoes.png",
        InvoiceCode: "_A0569",
        Compatibilities: [
          "SEPAY Smart",
          "SEPAY Smart (wit)",
          "SEPAY Smart Fixed",
        ],
        Fees: [
          {
            Price: 29.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Batterij voor de SEPAY Mini (K300)",
          },
          {
            Locale: "de-DE",
            Description: "Batterie für den Sepay Mini (K300)",
          },
          {
            Locale: "en-GB",
            Description: "Battery for the Sepay Mini (K300)",
          },
          {
            Locale: "fr-FR",
            Description: "Batterie pour Sepay Mini (K300)",
          },
        ],
        ImageUrl: "/images/webshop/Batterij_SEPAY_Mini.png",
        InvoiceCode: "_A0547",
        Compatibilities: ["SEPAY Mini 4G"],
        Fees: [
          {
            Price: 54.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Batterij voor de SEPAY Mobiel (G2)",
          },
          {
            Locale: "de-DE",
            Description: "Batterie für den Sepay Mobile (G2)",
          },
          {
            Locale: "en-GB",
            Description: "Battery for the Sepay Mobile (G2)",
          },
          {
            Locale: "fr-FR",
            Description: "Batterie pour Sepay Mobile (G2)",
          },
        ],
        ImageUrl: "/images/webshop/Batterij_SEPAY_Mobiel.png",
        InvoiceCode: "_A0545",
        Compatibilities: ["SEPAY Mobiel 4G", "SEPAY Smart Plus"],
        Fees: [
          {
            Price: 54.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Blauwe siliconen beschermhoes Buck Mobile (G2)",
          },
          {
            Locale: "de-DE",
            Description: "Blaue Silikon-Schutzhülle Buck Mobile (G2)",
          },
          {
            Locale: "en-GB",
            Description: "Blue silicone skin Buck Mobile (G2)",
          },
          {
            Locale: "fr-FR",
            Description: "Étui de protection en silicone bleu Buck Mobile (G2)",
          },
        ],
        ImageUrl:
          "/images/webshop/Beschermhoes-Pinautomaat_SEPAY-Mobiel-Blauw.png",
        InvoiceCode: "_A0572",
        Compatibilities: [],
        Fees: [
          {
            Price: 22.5,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Blauwe siliconen beschermhoes Buck Mobile (G5)",
          },
          {
            Locale: "de-DE",
            Description: "Blaue Silikon-Schutzhülle Buck Mobile 4G (G5)",
          },
          {
            Locale: "en-GB",
            Description: "Blue silicone skin Buck Mobile (G5)",
          },
          {
            Locale: "fr-FR",
            Description:
              "Étui de protection en silicone bleu Buck Mobile 4G (G5)",
          },
        ],
        ImageUrl:
          "/images/webshop/Beschermhoes-Pinautomaat_SEPAY-Mobiel-4G-Blauw.jpg",
        InvoiceCode: "_A0581",
        Compatibilities: ["SEPAY Mobiel 4G"],
        Fees: [
          {
            Price: 25.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Docking station voor Buck Mobile 4G",
          },
          {
            Locale: "de-DE",
            Description: "Ladestation  Buck Mobile 4G",
          },
          {
            Locale: "en-GB",
            Description: "Charging cradle Buck Mobile 4G",
          },
          {
            Locale: "fr-FR",
            Description: "Socle de chargement Buck Mobile 4G",
          },
        ],
        ImageUrl: "/images/webshop/Docking_station_g5.png",
        InvoiceCode: "_A0452",
        Compatibilities: ["SEPAY Mobiel 4G"],
        Fees: [
          {
            Price: 72.5,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Docking station voor Buck Smart",
          },
          {
            Locale: "de-DE",
            Description: "Ladestation  Buck Smart",
          },
          {
            Locale: "en-GB",
            Description: "Charging cradle Buck Smart",
          },
          {
            Locale: "fr-FR",
            Description: "Socle de chargement Buck Smart",
          },
        ],
        ImageUrl: "/images/webshop/Docking_station_smart.jpg",
        InvoiceCode: "_A0453",
        Compatibilities: [
          "SEPAY Smart",
          "SEPAY Smart (wit)",
          "SEPAY Smart Fixed",
        ],
        Fees: [
          {
            Price: 55.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Docking station voor Buck Smart Plus",
          },
          {
            Locale: "de-DE",
            Description: "Ladestation  Buck Smart Plus",
          },
          {
            Locale: "en-GB",
            Description: "Charging cradle Buck Smart Plus",
          },
          {
            Locale: "fr-FR",
            Description: "Socle de chargement Buck Smart Plus",
          },
        ],
        ImageUrl: "/images/webshop/Docking_station_smart_plus.jpg",
        InvoiceCode: "_A0454",
        Compatibilities: ["SEPAY Smart Plus"],
        Fees: [
          {
            Price: 32.5,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Internetkabel van 3 meter (zwart)",
          },
          {
            Locale: "de-DE",
            Description: "Internetkabel 1m",
          },
          {
            Locale: "en-GB",
            Description: "Internet cable 3m",
          },
          {
            Locale: "fr-FR",
            Description: "Câble Internet 3m",
          },
        ],
        ImageUrl: "/images/webshop/Internetkabel van 3 meter (zwart).png",
        InvoiceCode: "_A0400",
        Compatibilities: ["SEPAY Vast Neo", "SEPAY Vast Neo DUO"],
        Fees: [
          {
            Price: 10.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Kassakoppelingskabel voor de SEPAY Vast",
          },
          {
            Locale: "de-DE",
            Description: "Kassen-Kupplungskabel für den Buck Fixed",
          },
          {
            Locale: "en-GB",
            Description: "Cash register connection cable for the Buck Fixed",
          },
          {
            Locale: "fr-FR",
            Description: "Cash register connection cable for the Buck Fixed",
          },
        ],
        ImageUrl: "/images/webshop/sepay_vast_kassakoppelingskabel.png",
        InvoiceCode: "_A0505",
        Compatibilities: ["SEPAY Vast Neo", "SEPAY Vast Neo DUO"],
        Fees: [
          {
            Price: 32.5,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Oplaadstation K300 4G",
          },
          {
            Locale: "de-DE",
            Description: "Ladestation  (K300 4G)",
          },
          {
            Locale: "en-GB",
            Description: "Charging cradle (K300 4G)",
          },
          {
            Locale: "fr-FR",
            Description: "Socle de chargement (K300 4G)",
          },
        ],
        ImageUrl: "/images/webshop/Oplaadstation K300 4G.png",
        InvoiceCode: "_A0451",
        Compatibilities: ["SEPAY Mini 4G"],
        Fees: [
          {
            Price: 45.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Reinigingsdoekjes",
          },
          {
            Locale: "de-DE",
            Description: "Hygienetücher",
          },
          {
            Locale: "en-GB",
            Description: "Cleaning wipes",
          },
          {
            Locale: "fr-FR",
            Description: "Lingettes de nettoyage",
          },
        ],
        ImageUrl: "/images/webshop/Reinigingsdoekjes.png",
        InvoiceCode: "_A0419",
        Compatibilities: [
          "SEPAY Mini 4G",
          "SEPAY Mobiel 4G",
          "SEPAY Vast Neo",
          "SEPAY Vast Neo DUO",
          "SEPAY Smart",
          "SEPAY Smart Plus",
          "SEPAY Smart (wit)",
          "SEPAY Smart Fixed",
        ],
        Fees: [
          {
            Price: 4.25,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Siliconen cover (zwart) voor SEPAY Smart Plus (N86)",
          },
          {
            Locale: "de-DE",
            Description: "Schwarze Silikon-Schutzhülle Buck Smart Plus (N86)",
          },
          {
            Locale: "en-GB",
            Description: "Black silicone skin Buck Smart Plus (N86)",
          },
          {
            Locale: "fr-FR",
            Description:
              " Étui de protection en silicone noir Buck Smart Plus (N86)",
          },
        ],
        ImageUrl: "/images/webshop/Beschermhoes-Pinautomaat_Sepay-Plus-N86.png",
        InvoiceCode: "_A0583",
        Compatibilities: ["SEPAY Smart Plus"],
        Fees: [
          {
            Price: 25.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description:
              "Transparante (wit) siliconen beschermhoes Buck Mini (K300 4G)",
          },
          {
            Locale: "de-DE",
            Description:
              "Transparente (weiße) Silikon-Schutzhülle Buck Mini (K300 4G)",
          },
          {
            Locale: "en-GB",
            Description: "Transparant (white) silicone skin Buck Mini K300 4G)",
          },
          {
            Locale: "fr-FR",
            Description:
              "Étui de protection en silicone transparent  Buck Mini (K300 4G)",
          },
        ],
        ImageUrl: "/images/webshop/Beschermhoes-Pinautomaat_SEPAY-Mini-Wit.png",
        InvoiceCode: "_A0577",
        Compatibilities: ["SEPAY Mini 4G"],
        Fees: [
          {
            Price: 19.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description:
              "Transparante (wit) siliconen beschermhoes Buck Mobile (G2)",
          },
          {
            Locale: "de-DE",
            Description:
              "Transparente (weiße) Silikon-Schutzhülle Buck Mobile (G2)",
          },
          {
            Locale: "en-GB",
            Description: "Transparant (white) silicone skin Buck Mobile (G2)",
          },
          {
            Locale: "fr-FR",
            Description:
              "Étui de protection transparent en silicone - Buck Mobile (G2)",
          },
        ],
        ImageUrl: "/images/webshop/Beschermhoes-Pinautomaat_SEPAY-Mobiel.png",
        InvoiceCode: "_A0570",
        Compatibilities: [],
        Fees: [
          {
            Price: 22.5,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description:
              "Transparante (wit) siliconen beschermhoes Buck Mobile (G5)",
          },
          {
            Locale: "de-DE",
            Description:
              "Transparente (weiße) Silikon-Schutzhülle Buck Mobile 4G (G5)",
          },
          {
            Locale: "en-GB",
            Description: "Transparant (white) silicone skin Buck Mobile (G5)",
          },
          {
            Locale: "fr-FR",
            Description:
              "Étui de protection transparent en silicone - Buck Mobile 4G (G5)",
          },
        ],
        ImageUrl:
          "/images/webshop/Beschermhoes-Pinautomaat_SEPAY-Mobiel-4G-Wit.jpg",
        InvoiceCode: "_A0580",
        Compatibilities: ["SEPAY Mobiel 4G"],
        Fees: [
          {
            Price: 25.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description:
              "USB oplaadsnoer met magnetische micro-USB connector (1 meter)",
          },
          {
            Locale: "de-DE",
            Description:
              "USB-Ladekabel mit magnetischem Micro-USB-Stecker (1 Meter)",
          },
          {
            Locale: "en-GB",
            Description:
              "USB charging cable with magnetic micro-USB connector (1 meter)",
          },
          {
            Locale: "fr-FR",
            Description:
              "Câble de chargement USB avec connecteur micro-USB magnétique (1 mètre)",
          },
        ],
        ImageUrl: "/images/webshop/USB_Kabel_Magnetisch.png",
        InvoiceCode: "_A0503",
        Compatibilities: [],
        Fees: [
          {
            Price: 9.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description:
              "USB oplaadsnoer met magnetische micro-USB connector (2 meter)",
          },
          {
            Locale: "de-DE",
            Description:
              "USB-Ladekabel mit magnetischem Micro-USB-Stecker (2 Meter)",
          },
          {
            Locale: "en-GB",
            Description:
              "USB charging cable with magnetic micro-USB connector (2 meters)",
          },
          {
            Locale: "fr-FR",
            Description:
              "Câble de chargement USB avec connecteur micro-USB magnétique (2 mètres)",
          },
        ],
        ImageUrl: "/images/webshop/USB_Kabel_Magnetisch.png",
        InvoiceCode: "_A0504",
        Compatibilities: [],
        Fees: [
          {
            Price: 10.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description:
              "USB oplaadsnoer met magnetische USB-C connector (1 meter)",
          },
          {
            Locale: "de-DE",
            Description:
              "USB-Ladekabel mit magnetischem USB-C-Stecker (1 Meter)",
          },
          {
            Locale: "en-GB",
            Description:
              "USB charging cable with magnetic USB-C connector (1 meter)",
          },
          {
            Locale: "fr-FR",
            Description:
              "Câble de chargement USB avec connecteur USB-C magnétique (1 mètre)",
          },
        ],
        ImageUrl: "/images/webshop/USB_Kabel_Magnetisch.png",
        InvoiceCode: "_A0506",
        Compatibilities: [
          "SEPAY Mini 4G",
          "SEPAY Mobiel 4G",
          "SEPAY Smart",
          "SEPAY Smart Plus",
          "SEPAY Smart (wit)",
          "SEPAY Smart Fixed",
        ],
        Fees: [
          {
            Price: 9.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description:
              "USB oplaadsnoer met magnetische USB-C connector (2 meter)",
          },
          {
            Locale: "de-DE",
            Description:
              "USB-Ladekabel mit magnetischem USB-C-Stecker (2 Meter)",
          },
          {
            Locale: "en-GB",
            Description:
              "USB charging cable with magnetic USB-C connector (2 meters)",
          },
          {
            Locale: "fr-FR",
            Description:
              "Câble de chargement USB avec connecteur USB-C magnétique (2 mètres)",
          },
        ],
        ImageUrl: "/images/webshop/USB_Kabel_Magnetisch.png",
        InvoiceCode: "_A0507",
        Compatibilities: [
          "SEPAY Mini 4G",
          "SEPAY Mobiel 4G",
          "SEPAY Smart",
          "SEPAY Smart Plus",
          "SEPAY Smart (wit)",
          "SEPAY Smart Fixed",
        ],
        Fees: [
          {
            Price: 10.95,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Verhoogde Terminal houder T2",
          },
          {
            Locale: "de-DE",
            Description: "Erhöhter Klemmenhalter (T2)",
          },
          {
            Locale: "en-GB",
            Description: "Elevated Terminal Holder T2",
          },
          {
            Locale: "fr-FR",
            Description: "Support surélevé de terminal (T2)",
          },
        ],
        ImageUrl: "/images/webshop/Verhoogde Terminal houder T2 (Metaal).png",
        InvoiceCode: "_A0460",
        Compatibilities: [],
        Fees: [
          {
            Price: 125.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Verhoogde Terminal houder T6",
          },
          {
            Locale: "de-DE",
            Description: "Erhöhter Klemmenhalter T6",
          },
          {
            Locale: "en-GB",
            Description: "Elevated Terminal Holder T6",
          },
          {
            Locale: "fr-FR",
            Description: "Support surélevé de terminal T6",
          },
        ],
        ImageUrl: "/images/webshop/Verhoogde Terminal houder T6 (Metaal).png",
        InvoiceCode: "_A0461",
        Compatibilities: ["SEPAY Vast Neo", "SEPAY Vast Neo DUO"],
        Fees: [
          {
            Price: 125.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
      {
        ProductDescriptions: [
          {
            Locale: "nl-NL",
            Description: "Zwarte siliconen beschermhoes Buck Mobile (G5)",
          },
          {
            Locale: "de-DE",
            Description: "Schwarze Silikon-Schutzhülle Buck Mobile 4G (G5)",
          },
          {
            Locale: "en-GB",
            Description: "Black silicone skin Buck Mobile (G5)",
          },
          {
            Locale: "fr-FR",
            Description:
              "Coque de protection en silicone noir Buck Mobile 4G (G5)",
          },
        ],
        ImageUrl:
          "/images/webshop/Beschermhoes-Pinautomaat_SEPAY-Mobiel-4G-Zwart.jpg",
        InvoiceCode: "_A0582",
        Compatibilities: ["SEPAY Mobiel 4G"],
        Fees: [
          {
            Price: 25.0,
            Type: "BuyFee",
            Descriptions: null,
          },
        ],
      },
    ],
  },
  CampaignCodes: "",
  resultcode: 0,
  message: "",
  messagedetails: null,
};
