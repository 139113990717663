import React, { useContext, useMemo } from "react";
import TerminalCard from "../../components/pos/cards/TerminalCard";
import { PosContext } from "../../context/PosContext";
import { t } from "i18next";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import Celebration20Years from "../../components/animation/Celebration20Years";
import { useLocation } from "react-router-dom";

const Terminal: React.FC = () => {
  const navigate = useCustomNavigate();
  const {
    updateOrder,
    updateSteps,
    terminals,
    orders,
    activeOrderId,
    handleAccessoryQuantityChange,
    removeAccessory,
  } = useContext(PosContext);
  const activeOrder = orders.find((order) => order.orderId === activeOrderId);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isBuckaroo20Years =
    queryParams.get("partner") ===
    "38237ac2b44eaf3aec72e33ff81832858f50891022562d887b9eac9b26574f8f";

  const handleSelectTerminal = (invoiceCode: string) => {
    const currentSelectedAccessories = activeOrder?.selectedAccessories || [];
    currentSelectedAccessories.forEach((accessory) => {
      const {
        accessory: { InvoiceCode },
        quantity,
      } = accessory;
      if (quantity > 0) {
        handleAccessoryQuantityChange(InvoiceCode, -quantity);
      } else {
        removeAccessory(InvoiceCode);
      }
    });

    const selectedTerminal = terminals.find(
      (terminal) => terminal.InvoiceCode === invoiceCode
    );
    updateOrder({ selectedTerminal: selectedTerminal });
    updateSteps({ terminal: true });
    navigate("/pos/subscription");
  };

  const SORT_ORDER = [
    "buck smart",
    "buck smart plus",
    "buck fixed neo",
    "buck mobile 4g",
    "buck fixed neo duo",
    "buck mini 4g",
    "buck smart fixed",
  ].map((item) => item.toLowerCase());

  const orderedTerminals = useMemo(() => {
    const filteredAndSortedTerminals = terminals
      .filter((terminal) =>
        terminal.ProductDescriptions.some((description) => {
          const descLower = description.Description.toLowerCase().trim();
          return (
            description.Locale.startsWith("en") &&
            SORT_ORDER.includes(descLower)
          );
        })
      )
      .sort((a, b) => {
        const descriptionA = a.ProductDescriptions.find((desc) =>
          desc.Locale.startsWith("en")
        )
          ?.Description.toLowerCase()
          .trim();
        const descriptionB = b.ProductDescriptions.find((desc) =>
          desc.Locale.startsWith("en")
        )
          ?.Description.toLowerCase()
          .trim();

        const indexA = SORT_ORDER.indexOf(descriptionA!);
        const indexB = SORT_ORDER.indexOf(descriptionB!);

        return indexA - indexB;
      });

    return filteredAndSortedTerminals;
  }, [terminals]);

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <Celebration20Years trigger={isBuckaroo20Years} />
      <h1 className="text-3xl font-bold mb-8 text-left">
        {t("pos.terminal.title")}{" "}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
        {orderedTerminals.map((terminal) => (
          <TerminalCard
            key={terminal.InvoiceCode}
            terminal={terminal}
            onSelect={() => handleSelectTerminal(terminal.InvoiceCode)}
          />
        ))}
      </div>
    </div>
  );
};

export default Terminal;
