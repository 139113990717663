import React, { useEffect, useState } from "react";
import confetti from "canvas-confetti";

interface Celebration20YearsProps {
  trigger: boolean;
}

const Celebration20Years: React.FC<Celebration20YearsProps> = ({ trigger }) => {
  const [showCelebration, setShowCelebration] = useState<boolean>(false);

  useEffect(() => {
    const hasSeenCelebration = sessionStorage.getItem("hasSeenCelebration");

    if (trigger && !hasSeenCelebration) {
      sessionStorage.setItem("hasSeenCelebration", "true");
      setShowCelebration(true);

      confetti({
        particleCount: 200,
        spread: 100,
        origin: { y: 0.6 },
        colors: [
          "#CDF564",
          "#163255",
          "#EFE9E4",
          "#E1E3DE",
          "#9BA091",
          "#B7ED44",
        ],
      });

      setTimeout(() => setShowCelebration(false), 3000);
    }
  }, [trigger]);

  if (!showCelebration) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="flex flex-col items-center justify-center">
        <img
          src={require("../../images/png/Buckaroo_20years.png")}
          alt="Buckaroo 20 Years!"
          className="h-64 w-auto mb-4 drop-shadow-lg"
        />
      </div>
    </div>
  );
};

export default Celebration20Years;
