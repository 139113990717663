import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationDE from "./locales/de/translation.json";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationNL from "./locales/nl/translation.json";
import translationIT from "./locales/it/translation.json";

const resources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  nl: {
    translation: translationNL,
  },
  it: {
    translation: translationIT
  }
};

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.get("lang");

i18n.use(initReactI18next).init({
  resources,
  lng: lang ?? "nl",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
