import React, { useContext, useState } from "react";
import { IProduct } from "../../../interfaces/ITerminal";
import { PosContext } from "../../../context/PosContext";
import Modal from "../ui/Modal";
import { useTranslation } from "react-i18next";
import { getLocalizedDescription } from "../utilities/getLocalizedDescription";
import { t } from "i18next";
import InfoCircleIcon from "../../../icons/InfoCircleIcon";
import GreenCheckIcon from "../../../icons/GreenCheckIcon";
import Percent20Discount from "../../../icons/20PercentDiscount";
import { useLocation } from "react-router-dom";

interface TerminalCardProps {
  terminal: IProduct;
  onSelect: () => void;
}

const featureOrder = [
  "battery life",
  "connection",
  "contactless payment",
  "cash register integration",
  "thermal receipt printer",
  "kassiersunit",
];

const TerminalCard: React.FC<TerminalCardProps> = ({ terminal, onSelect }) => {
  const { orders, activeOrderId } = useContext(PosContext);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isBuckaroo20Years =
    queryParams.get("partner") ===
    "38237ac2b44eaf3aec72e33ff81832858f50891022562d887b9eac9b26574f8f";

  const order = orders.find((order) => order.orderId === activeOrderId);
  const isSelected =
    terminal.InvoiceCode === order?.selectedTerminal?.InvoiceCode;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const featuresArray = Array.isArray(terminal.ProductFeatures)
    ? terminal.ProductFeatures
    : [];

  const sortedFeatures = featuresArray.sort((a, b) => {
    const languageCode = "en-gb";

    const aName = getLocalizedDescription(a.Name, languageCode)
      .toLowerCase()
      .trim();
    const bName = getLocalizedDescription(b.Name, languageCode)
      .toLowerCase()
      .trim();

    const aIndex =
      featureOrder.indexOf(aName) !== -1
        ? featureOrder.indexOf(aName)
        : Infinity;
    const bIndex =
      featureOrder.indexOf(bName) !== -1
        ? featureOrder.indexOf(bName)
        : Infinity;
    return aIndex - bIndex;
  });

  return (
    <>
      <div
        className={`flex flex-col justify-between mb-7 rounded-xl overflow-hidden shadow-lg transition-all duration-300 ease-in-out transform ${
          isSelected
            ? "bg-buckaroo-gray-300 hover:shadow-xl"
            : "bg-buckaroo-gray-50 hover:bg-buckaroo-gray-200 hover:shadow-xl"
        } cursor-pointer`}
        onClick={onSelect}
      >
        <div className="p-4">
          <h2
            className={`relative font-semibold text-lg mb-3 text-center ${
              isSelected ? "text-black" : "text-gray-800"
            }`}
          >
            {getLocalizedDescription(
              terminal.ProductDescriptions,
              currentLanguage
            )}
          </h2>
          <div
            className="absolute top-4 right-3 transform translate-x-1/4 -translate-y-1/4 flex items-center justify-center 
                        w-6 h-6 text-xs font-bold text-buckaroo-blue-500 bg-transparent rounded-full cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenModal();
            }}
          >
            <InfoCircleIcon className="w-5 h-5" />
          </div>

          <div className="relative w-full h-36 flex justify-center items-center">
            <img
              src={`https://www.sepay.nl${terminal.ImageUrl}`}
              alt={terminal.ProductName}
              className="w-full h-36 object-contain"
            />
            {isBuckaroo20Years && (
              <div className="absolute top-1/2 left-[13%] transform -translate-x-1/2 -translate-y-1/2">
                <Percent20Discount />
              </div>
            )}
          </div>

          <ul className="flex flex-col items-start text-sm">
            {sortedFeatures?.map((feature, idx) => {
              const localizedDescription = getLocalizedDescription(
                feature.Name,
                currentLanguage
              );
              const localizedValue = getLocalizedDescription(
                feature.Values,
                currentLanguage
              );

              const affirmativeExpressions = [
                "Yes",
                "Ja",
                "Oui",
                "yes",
                "ja",
                "oui",
              ];

              const isAffirmative =
                affirmativeExpressions.includes(localizedValue);

              return (
                <li
                  key={idx}
                  className={`mb-2 flex items-center justify-between w-full ${
                    isSelected ? "text-black" : "text-gray-600"
                  }`}
                >
                  <span>{localizedDescription}</span>

                  {isAffirmative ? (
                    <span className="font-semibold">
                      <GreenCheckIcon className="w-6 h-6" />
                    </span>
                  ) : (
                    <span className="font-semibold">{localizedValue}</span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className={`text-center text-lg font-medium p-2 cursor-pointer bg-primary-500 text-black `}
          onClick={(e) => {
            e.stopPropagation();
            onSelect();
          }}
        >
          {isSelected
            ? t("pos.buttons.select.selected")
            : t("pos.buttons.select.name")}
        </div>
      </div>
      {isModalOpen && (
        <Modal
          onClose={handleCloseModal}
          className="max-w-lg"
          title={getLocalizedDescription(
            terminal.ProductDescriptions,
            currentLanguage
          )}
        >
          <div>
            <img
              src={`https://www.sepay.nl${terminal.ImageUrl}`}
              alt={terminal.ProductName}
              className="w-full"
            />
            <ul className="flex flex-col items-start">
              {sortedFeatures?.map((feature, idx) => (
                <li
                  key={idx}
                  className={`mb-2 flex items-center justify-between w-full ${
                    isSelected ? "text-black" : "text-gray-600"
                  }`}
                >
                  <span>
                    {getLocalizedDescription(feature.Name, currentLanguage)}
                  </span>

                  <span>
                    {getLocalizedDescription(feature.Values, currentLanguage)}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TerminalCard;
