import React, {
  BaseSyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Input from "../../components/ui/Input";
import { validationSchema } from "../../utils/validationSchema";
import CompanySearch from "../companySearch/CompanySearch";
import SelectCountry from "../dropdown/SelectCountry";
import { useRequest } from "../../hooks/useRequest";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { OverlayStatus } from "../../enums/OverlayStaus";
import LoadingOverlay from "../ui/LoadingOverlay";
import { PosContext } from "../../context/PosContext";
import { transformMigrateToPayload } from "../../utils/transformMigrateToPayload";
import { transformOrdersToRequestPayload } from "../../utils/transformOrdersToRequestPayload";
import Modal from "../pos/ui/Modal";
import AddressReqired from "../pos/ui/AddressRequired";
import { transformTapToPayPayload } from "../../utils/transformTapToPayPayload";
import clsx from "clsx";

export type OnboardingFormData = {
  companyName: string;
  cocNumber: string;
  firstName: string;
  lastname: string;
  countryCode: string;
  email: string;
  password: string;
  kvk: number;
  recaptcha: any;
};

export interface IOnboarding {
  setOverlayStatus: (status: OverlayStatus) => void;
  overlayStatus: OverlayStatus;
  setIsFormSubmitted: (isSubmitted: boolean) => void;
  setFirstName: (name: string) => void;
  setCompanyName: (company: string) => void;
}

const OnboardingForm: React.FC<IOnboarding> = ({
  setOverlayStatus,
  overlayStatus,
  setIsFormSubmitted,
  setFirstName,
  setCompanyName,
}) => {
  const {
    orderType,
    migrate,
    softPosCreationData,
    orders,
    aggregatedAccessories,
    selectedBundle,
    deliveryAddress,
  } = useContext(PosContext);

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [isNetherlands, setIsNetherlands] = useState<boolean>(true);
  const schema = validationSchema(isNetherlands);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>();
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isAddressRequired, setIsAddressRequired] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [finalPayloadState, setFinalPayloadState] =
    useState<OnboardingFormData>();

  const { t, i18n } = useTranslation();
  const methods = useForm<OnboardingFormData>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const useQueryParameters = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(Array.from(searchParams.entries()));
    return params;
  };
  const location = useLocation();
  const queryParams = useQueryParameters();
  const isPartnerYellow =
    queryParams.partner ===
    "eb653c06e00ff430289f6ae7b3f899b60f2b0e02f2f082f646ce9f2c99b212ba";

  const { handleRequest: handleCreate } = useRequest("/v1/onboarding", "post");

  const handleExitOverlay = () => {
    setOverlayStatus(OverlayStatus.None);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isAddressRequired) {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const country = params.get("country") || "NL";
    setSelectedCountry(country.toUpperCase());
    methods.setValue("countryCode", country.toUpperCase());
    setIsNetherlands(country.toUpperCase() === "NL");
  }, [location.search, methods]);

  const constructPosRelatedPayload = () => {
    const { orderLines, locations, deliveryAddressMigrate } =
      transformMigrateToPayload(migrate, selectedBundle);

    const { orderLinesTapToPay, locationsTapToPay, deliveryAddressTapToPay } =
      transformTapToPayPayload(softPosCreationData);

    const orderPayload = transformOrdersToRequestPayload(
      orders,
      deliveryAddress,
      aggregatedAccessories,
      selectedBundle
    );

    if (!orderType) {
      return { onboardingType: "OnlineOnly" };
    }

    switch (orderType.flow) {
      case "Migrate":
        return {
          onboardingType: orderType.type,
          order: {
            locations,
            orderLines,
            deliveryAddress: deliveryAddressMigrate,
          },
        };
      case "TapToPay":
        return {
          onboardingType: orderType.type,
          order: {
            locations: locationsTapToPay,
            orderLines: orderLinesTapToPay,
            deliveryAddress: deliveryAddressTapToPay,
          },
        };
      case "POS":
        return {
          onboardingType: orderType.type,
          order: { ...orderPayload },
        };
      default:
        return { onboardingType: "OnlineOnly" };
    }
  };

  const onSubmit = async (
    { kvk, coc, firstname, ...data }: any,
    e?: BaseSyntheticEvent<Object | any | undefined>
  ) => {
    if (acceptedTerms) {
      setOverlayStatus(OverlayStatus.Loading);
      setIsSubmitting(true);
      const token = executeRecaptcha && (await executeRecaptcha());
      setRecaptchaToken(token);
      const kvkRes = isNetherlands
        ? {
            companyName: kvk.companyName || "",
            cocNumber: kvk.cocNumber || "",
            firstname: firstname || "",
          }
        : {
            cocNumber: coc.cocNumber || "",
            firstname: firstname || "",
          };
      const language = i18n.language || "nl";

      const posPayload = constructPosRelatedPayload();

      const finalPayload = {
        language,
        recaptchaToken: token,
        customParameters: {
          ...queryParams,
        },
        ...kvkRes,
        ...data,
        ...posPayload,
      };
      setFinalPayloadState(finalPayload);
      handleCreate(
        finalPayload,
        (res) => {
          setFirstName(firstname);
          if (isNetherlands && kvk?.companyName) {
            setCompanyName(kvk.companyName);
          }
          if (res?.data?.requiresAddressInput === true) {
            setIsAddressRequired(true);
            setIsModalOpen(true);
            setIsSubmitting(false);
            setOverlayStatus(OverlayStatus.None);
            toast.success(t("toast.addressRequired"));
          } else {
            setOverlayStatus(OverlayStatus.Success);
            setIsFormSubmitted(true);
            setIsSubmitting(false);
            toast.success(t("toast.success"));
          }
        },
        (res) => {
          if (
            res?.response?.data?.error ===
            "The following required data was missing: companyInfo."
          ) {
            toast.error(t("errors.toastError.kvkNum"));
          } else {
            toast.error(t("toast.error"));
          }
          setOverlayStatus(OverlayStatus.Error);
          setIsSubmitting(false);
          setTimeout(() => {
            setOverlayStatus(OverlayStatus.None);
          }, 1500);
        }
      );
    }
  };

  useEffect(() => {
    methods.reset();
    setAcceptedTerms(false);
  }, [isNetherlands, methods, location]);

  useEffect(() => {
    if (isAddressRequired) {
      setIsModalOpen(true);
    }
  }, [isAddressRequired]);

  const validateRecaptcha = async () => {
    const token = executeRecaptcha && (await executeRecaptcha());
    if (!token) {
      return "Please complete the reCAPTCHA";
    }
    setRecaptchaToken(token);
    return true;
  };

  const toggleTermsCheckbox = () => {
    setAcceptedTerms(!acceptedTerms);
  };

  const handleVerify = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const links = {
    en: {
      terms: "https://www.buckaroo.eu/large-corporations/terms-and-conditions",
      pricing: isPartnerYellow
        ? "https://www.buckaroo.eu/abn-amro/pricing"
        : "https://www.buckaroo.eu/pricing",
    },
    nl: {
      terms: "https://www.buckaroo.nl/grootzakelijk/voorwaarden",
      pricing: isPartnerYellow
        ? "https://www.buckaroo.nl/abn-amro/tarieven"
        : "https://www.buckaroo.nl/tarieven",
    },
    fr: {
      terms: "https://www.buckaroo.be/fr/grandes-entreprises/conditions",
      pricing: isPartnerYellow
        ? "https://www.buckaroo.eu/abn-amro/pricing"
        : "https://www.buckaroo.be/fr/tarifs",
    },
    de: {
      terms: "https://www.buckaroo.eu/terms-and-conditions",
      pricing: isPartnerYellow
        ? "https://www.buckaroo.eu/abn-amro/pricing"
        : "https://www.buckaroo.be/de/tarife",
    },
  };

  const currentLanguageLinks =
    links[i18n.language as keyof typeof links] || links["nl"];

  const sentenceKey = isPartnerYellow
    ? `${t(`links.partnerYellow`)}`
    : `${t(`links.default`)}`;
  const computeCocLabel = () => {
    switch (selectedCountry) {
      case "BE":
        return t("coc.label_b");
      case "FR":
        return t("coc.label_f");
      case "NL":
        return t("coc.label_n");
      case "DE":
        return t("coc.label_g");
      case "IT":
        return t("coc.label_i");
      default:
        return t("coc.label_n");
    }
  };

  const computeCocPlaceholder = () => {
    if (selectedCountry === "DE") {
      return t("placeholders.vat");
    }
    if (selectedCountry === "IT") {
      return t("coc.placeholder_i");
    }
    return t("placeholders.kvk");
  };

  return (
    <FormProvider {...methods}>
      {isModalOpen && (
        <Modal onClose={handleCloseModal} className="max-w-lg">
          <div>
            <h1 className="text-primary-600 text-xl text-center">
              {t("headers.addressReq")}
            </h1>
            <p className="mt-3 text-center mb-4 text-base text-gray-800">
              {t("headers.addressDesc")}
            </p>
            <AddressReqired
              finalPayloadState={finalPayloadState}
              setOverlayStatus={setOverlayStatus}
              setIsFormSubmitted={setIsFormSubmitted}
              overlayStatus={overlayStatus}
            />
          </div>
        </Modal>
      )}
      <LoadingOverlay status={overlayStatus} onExit={handleExitOverlay} />
      <form
        onSubmit={methods.handleSubmit((data) => {
          onSubmit(data);
        })}
        className="flex flex-col justify-start w-full bg-inherit lg:px-0 md:w-2/3 max-w-lg mx-auto"
      >
        {recaptchaToken === null && (
          <Controller
            name="recaptcha"
            control={methods.control}
            rules={{ validate: validateRecaptcha }}
            render={({ field }) => (
              <div className="">
                <GoogleReCaptcha onVerify={handleVerify} {...field} />
              </div>
            )}
          />
        )}

        {recaptchaToken !== null && (
          <input type="hidden" name="recaptchaToken" value={recaptchaToken} />
        )}

        <div className="text-center md:text-left text-2xl lg:text-3xl font-bold text-[#1C1C1B] w-full mb-4">
          {t("headers.form")}
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between w-full md:space-x-5">
          <Input
            id="firstname"
            name="firstname"
            label={t("inputs.firstName")}
            type="text"
            placeholder={t("placeholders.firstName")}
          />
          <Input
            id="lastname"
            name="lastname"
            label={t("inputs.lastName")}
            type="text"
            placeholder={t("placeholders.lastName")}
            onChange={(e) => {
              methods.setValue("lastname", e.target.value);
            }}
          />
        </div>

        {!isNetherlands && (
          <>
            <div className="z-20">
              <SelectCountry
                onCountryChange={(country) => {
                  setSelectedCountry(country);
                  setIsNetherlands(country === "NL");
                }}
                prefilledCountry={selectedCountry}
              />
            </div>
            <div className="relative w-full mt-2">
              <CompanySearch
                name="coc"
                isNetherlands={isNetherlands}
                label={computeCocLabel()}
                placeholder={computeCocPlaceholder()}
                selectedCountry={selectedCountry}
              />
            </div>
          </>
        )}
        {isNetherlands && (
          <>
            <div className="z-20">
              <SelectCountry
                onCountryChange={(country) => {
                  setSelectedCountry(country);
                  setIsNetherlands(country === "NL");
                }}
                prefilledCountry={selectedCountry}
              />
            </div>
            <div className="relative w-full">
              <CompanySearch
                isNetherlands={isNetherlands}
                name="kvk"
                label={`${t("inputs.kvk")}`}
                placeholder={`${t("placeholders.kvk")}`}
              />
            </div>
          </>
        )}
        <Input
          id="email"
          name="email"
          label={t("inputs.email")}
          type="email"
          placeholder={t("placeholders.email")}
          onChange={(e) => {
            methods.setValue("email", e.target.value);
          }}
        />
        <div className="flex items-center mt-4">
          <label
            htmlFor="acceptTerms"
            className="flex items-center cursor-pointer"
          >
            <input
              type="checkbox"
              id="acceptTerms"
              name="acceptTerms"
              checked={acceptedTerms}
              onChange={toggleTermsCheckbox}
              className="checkbox form-checkbox text-sm h-5 w-5 text-primary-500 border-primary-300 rounded-2xl
              transition duration-150 ease-in-out cursor-pointer mr-3
              "
            />
          </label>
          <label htmlFor="acceptTerms" className="select-none text-sm">
            <Trans
              i18nKey={sentenceKey}
              components={[
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href={currentLanguageLinks.terms}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline hover:text-primary-800 text-sm"
                />,
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href={currentLanguageLinks.pricing}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline hover:text-primary-800 text-sm"
                />,
              ]}
            ></Trans>
          </label>
        </div>
        <button
          data-testid="submit"
          type="submit"
          className={clsx(
            "w-full px-10 py-3 mt-5 text-center text-sm font-bold tracking-widest uppercase transition-all ease-in-out duration-00",
            "bg-primary-500 text-black border border-primary-500 rounded-full",
            "hover:bg-secondary-500 hover:text-primary-500 hover:shadow-md",
            !acceptedTerms || isSubmitting || isModalOpen
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
          )}
          disabled={!acceptedTerms || isSubmitting || isModalOpen}
        >
          {t("buttons.submitForm")}
        </button>
      </form>
    </FormProvider>
  );
};

export default OnboardingForm;
